import { blue } from "ansi-colors";
import React from 'react'

const site = {
    name: "Melodix",
    shortDescription: "Find out who's the worlds best drummer",
    description: "ABC DEF",
    categories: ["game","music"],
    tags: ["music","game"],
    style: {
        menu: {
            
        }
    },
    hidden: "T.B.A.",
    hiddenAlert: <div>play with music. subscribe for the newsletter to learn more about it ...<br/>🎵🎶🤘🎸🥁</div>,
}


export default site

// AppSingOMeter = () => {
//     {
//         {}
//     }
// }