import React from 'react'
import { blue } from "ansi-colors";
import icon from './icon'

import {ImageBalloon,ImagePhone} from './Images'

import AppPage from '../../components/AppPage'

const getContent = () => {
    return <div><p>Meeeeeeeh!*</p>
    <p>Did you already practice piano today? <br/>Meheheeehee, I challenge you to a symphonic piano challenge!<br/>
    Meeeeeh...<br/>
    Download Piano Goat now and play those blacks and whites. Ta ta ta daaaaaa. Just press em and win. Or start again. And learn something.
    </p>
    <ImagePhone style={{width: '100%',margin: '10px 0px'}}/>
   <p>A goat, a cow and a piano. What at first seems like a pretty simple exercise soon develops a mindblowing twist. Meeeeeh.</p><p>Discover the real tragedy...</p>
    <ImageBalloon style={{width: '100%',margin: '10px 0px'}}/>
    <p>Do you have what it takes to be a real goat pianist?<br/>Let's find out!!! -> start playing now 🎹🤘</p>
    <p>Meh meh meh,<br/>Yours</p>
    <p>Goat</p>
    <p><small>* = Welcome</small></p></div>
}
const getPage = () => {
    const content = getContent()
    return AppPage({app:site,content})
}
const site = {
    name: "Piano Goat",
    shortDescription: "Helpless Goat + Cow + Piano\n= Awesome!!!",
    description: "ABC DEF",
    categories: ["music"],
    tags: ["game","music","learn","piano"],
    style: {
        menu: {
            background: ""
        }
    },
    pageURL: "PianoGoat",
    downloadLinks: {
        appStore: "https://apps.apple.com/us/app/piano-goat/id1491984158?mt=8",
        playStore: "COMING_SOON"
    },
    icon, 
    getContent,
    getPage
}


export default site