import React from "react"
import { Link,navigate } from "gatsby"


import SingOMeter from './SingOMeter'
import GrooveOMeter from './GrooveOMeter'
import DrumChampion from './DrumChampion'
import GuitarChampion from './GuitarChampion'
import PianoChampion from './PianoChampion'
import Melodix from './Melodix'
import Overlap from './Overlap'
import Buggy from './Buggy'
import PianoGoat from './PianoGoat'
import RhythmBear from './RhythmBear'
import MyMusic from './MyMusic'

import {FaArrowAltCircleRight,FaAngleRight} from 'react-icons/fa'
import Popup from "../components/Popup";


const sites = [SingOMeter,GrooveOMeter,GuitarChampion,PianoChampion,DrumChampion,Melodix,Overlap,Buggy,PianoGoat,RhythmBear]
export default sites

export function ListOfAllApps() {
    let tags = []
    let output = []
    for (const site of sites) {
        output.push(createEntryForApp(site))
        for (const tag of site.tags) {
            if (tags.indexOf(tag) < 0)
                tags.push(tag);
        }
    }
    tags.push("ABC")
    // console.log("output",output)
    return <React.Fragment>{"TEST"}<ul style={{listStyleType:'none', padding: 0, margin: 0}}>{output}</ul></React.Fragment>
}

export function TagsOfAllApps() {
    let tags = []
    for (const site of sites) {
        for (const tag of site.tags) {
            if (tags.indexOf(tag) < 0)
                tags.push(tag);
        }
    }
    return tags;
}

export function ListOfAllAppsByCategory() {
    // let categories = {}
    // for (const site of sites) {
    //     addOrCreateCategory(categories,site)
    // }
    // console.log(categories)

    let output = []
    const sectionMusic = {title: "Apps > Music", sites: [SingOMeter,PianoGoat,RhythmBear,GrooveOMeter,GuitarChampion,PianoChampion,DrumChampion]}
    output.push(createSectionForSites(sectionMusic))
    const sectionGames = {title: "Apps > Games", sites: [Buggy,Overlap,Melodix]}
    output.push(createSectionForSites(sectionGames))
    const sectionMyMusic = {title: "My Music", sites: MyMusic}
    output.push(createSectionForSites(sectionMyMusic))
    // output = Object.keys(categories).map(const category = categories[categoryName])
    console.log("output",output)
    return <ul className="listOfApps">{output}</ul>
}

const createSectionForSites = (section) =>{
    if (!section || !section.sites)
        return
    const subPagesForCategory = section.sites.map(createEntryForApp)
    return <li><div className="listOfAllApps">
        <h2>{section.title}</h2>
        <ul>{subPagesForCategory}</ul>
        
    </div></li>
}

const createEntryForApp = (app) => {
    // console.log("icon",app.icon)
    const tags = app.tags.map(tag=>{return (<span>{tag}<br/></span>)})
    const background = app.style && app.style.menu ? app.style.menu.background : ""
    const hidden = app.hidden ? <span className="hidden">{app.hidden}</span> : ""
    if (app.hidden)
        app.pageURL = undefined
    // console.log("tags",tags)
    const header = app.pageURL != undefined ? <Link to={"/"+app.pageURL+"/"}><h3>{app.name}</h3></Link> : <h3>{app.name}</h3> 
    return (<li style={{background}}>
        <div onClick={()=>{app.pageURL !== undefined ? navigate("/"+app.pageURL+"/") : (app.onClick != undefined ? app.onClick() : Popup.alert(app.hiddenAlert || <span>no no no no no!!!<br/>please be patient ... this is soon available for your delight</span>))}} style={{display: 'flex', position: 'relative', cursor: 'pointer'}} title={app.tooltip || app.name}>
        {app.icon ? <app.icon /> : "" }<div className="listContent">
        {header}
        <div className="shortDescription">{app.shortDescription}</div></div>
        <small className="tags">{tags}</small>   
        {app.pageURL !== undefined && 
        <Link to={"/"+app.pageURL+"/"}></Link>
        }
        {/* <FaAngleRight style={{position:'absolute',right: '5px', top: '0px', height: '100%', fontSize: '10px', color:'#EEE' }}/> */}
        {hidden}
        </div>
    </li>)
}

function addOrCreateCategory(categories,site) {
    const category = site.categories && site.categories.length > 0 ? site.categories[0] : ""
    if (categories[category] != undefined) {
        categories[category].sites.push(site)
    } else {
        categories[category] = {sites:[site]}
    }
}