import React from 'react'
import { blue } from "ansi-colors";
import icon from './icon'
import AppPage from '../../components/AppPage'
import {Image1,Image2,Image3} from './Images'

const imageStyle = {width:'33.3%',display:'inline-block'}

const getContent = () => {
    return <div>
        <p>Sometimes opinions are overlapping. Or thoughts. Or people. Or good pancakes ... But this <strong>overlapping experience</strong> is way beyond all those deeply existential thoughts.</p>
        <p>Cover the truth - and everything else - and emerse yourself in a world where nothing should stay uncovered. <br/>Download Overlap now and embrace a colorful simplicity.</p>
        <Image1 style={imageStyle}/>
        <Image2 style={imageStyle}/>
        <Image3 style={imageStyle}/>
        <p>Get it on the App Store: <a href="https://apps.apple.com/us/app/overlap-black-white/id1324109825?mt=8">Overlap</a></p>
    </div>
}

const getPage = () => {
    const content = getContent()
    return AppPage({app:site,content})
}

const site = {
    name: "Overlap",
    shortDescription: "Black, white and a lot of colors",
    description: "ABC DEF",
    categories: ["game"],
    tags: ["game","relaxing","puzzle"],
    style: {
        menu: {
            background: ""
        }
    },
    getContent,
    getPage,
    downloadLinks: {
        appStore:"https://apps.apple.com/us/app/overlap-black-white/id1324109825?mt=8",
        playStore:"COMING_SOON"
    },
    pageURL:'Overlap',
    icon
}
export default site