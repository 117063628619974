import { Link,navigate } from "gatsby"
import PropTypes from "prop-types"
import React,{useState} from "react"


//onMouseDown={()=>{setState({left:randomLeft,top:randomTop})}}
const HardButton = () => {
  const [{left,top},setState] = useState({left:0,top:0})
  const randomLeft = left + (Math.random() > 0.5 ? 50 : -50);
  const randomTop = top + (Math.random() > 0.5 ? 50 : -50);
  console.log(randomLeft)
  return <a className="trickyButton" style={{left,top}} onMouseOver={()=>{setState({left:randomLeft,top:randomTop})}}  href="http://twitter.com/realPianoBear">click here</a>
}

export default HardButton
