import { blue } from "ansi-colors";
import React from "react"
import icon from './icon'
import {Link} from 'gatsby'
import {ImageMeter,ImageDetection} from './Images'
import AppPage from '../../components/AppPage'

const getContent = () => {
    return <div>
    <p>Want to learn singing or get better at it? Sing-O-Meter is the app for singers to practice singing in perfect pitch. 
    </p>
    <h2>Sing in tune</h2>
    <p>The app shows you if you sing off-key (red) or perfect (green).</p>
    {/* <img className="sing_o_meter_image_1" src={require('../../images/SingOMeter_sing_in_tune.png')} style={{width: '200px',float:'right', margin: '5px 0px 5px 5px'}}/> */}
    <ImageMeter style={{width: '200px',float:'right', margin: '5px 0px 5px 5px'}} />
    <h2>Am I singing ok? ... Pitch</h2>
    <p>Never wonder if you sing the right notes! Sing-O-Meter shows you what you do good and what needs improvement.</p>
    <h2>Learn faster</h2>
    <p>Get immediate feedback and improve your learning. No need to wait a week for your next vocal lesson. Sing-O-Meter is the perfect tool between the lessons when you need to know how good you are doing.</p>
    <h2>App for singers ...</h2>
    <p>Quote: "When I'm at home I use Sing-O-Meter to know if I'm singing correctly"</p>
    <p>Quote: "Sing-O-Meter made it so much easier to practice everyday ... and it's fun to see how good I was yesterday"</p>
    <h2>... and for all musicians</h2>
    <p>"When you play a musical instrument you actually sing with your instrument"</p>
    <p>That's why I think all musicians benefit from practicing with Sing-O-Meter. You practice your perception of pitch and you will be more confident in your singin abilities. This leads to you probably singing way more than before.</p><p>Enjoy singing good with the help of this app.</p>
    <p>Additionally it has been reported that Sing-O-Meter is also used by violin teachers and trumpet players, so I assume all players of string instruments like violin, viola, violon chello and double bass benefit from the usage as well as wind and brass instrument players like trumpet, clarinet, saxophones etc.</p>
    <p><img className="sing_o_meter_image_1" src={require('../../images/SingOMeter_line_singing.png')} style={{width: '100%',marginTop: '10px'}}/></p>
    {/* <ImageDetection style={{width: '100%',margin: '10px 0px'}} /> */}
    <h2>Get the App for iOS and Android</h2>
    <p>Download for free and start singing now: <br/>
    <a href="https://apps.apple.com/us/app/sing-o-meter-perfect-pitch/id1448696483">Sing-O-Meter for iOS on the App Store</a><br/>
    Android coming soon!</p>
    <p>
        <Link to="SingOMeter/GettingStarted">Getting Started with Sing-O-Meter</Link><br/>
        <Link to="SingOMeter/Help">Help for Sing-O-Meter</Link><br/>
        <Link to="SingOMeter/Privacy">Privacy Policy for Sing-O-Meter</Link>
    </p>
</div>
}

const getPage = () => {
    const content = getContent()
    return AppPage({app:site,content})
}

const site = {
    name: "Sing-O-Meter (Pro)",
    shortDescription: <span>Learn to sing like a pro<br/>A best practice for everyone</span>,
    tooltip: "Learn more about Sing-O-Meter and the Pro version",
    description: "ABC DEF",
    categories: ["music"],
    tags: ["music","singing","learn","pitch"],
    style: {
        menu: {
            background: ""
        }
    },
    icon,
    pageURL: "SingOMeter",
    downloadLinks: {
        appStore: "https://apps.apple.com/us/app/sing-o-meter-perfect-pitch/id1448696483?mt=8",
        playStore: "COMING_SOON"
    },
    fullSite: <p>Welcome to singometer</p>,
    getContent,
    getPage
}
export default site