import { blue } from "ansi-colors";
import icon from './icon'
import React from 'react'
import AppPage from "../../components/AppPage";

import {Image1,Image2,Image3,Image4} from './Images'

const imageStyle = {width:'25%',display:'inline-block'}

const getContent = () => {
    return <div>
        <p>You got rhythm? <br/>"I got rhythm!" - R. Bear</p>
        <p>No matter if you just want to learn about groove, become a better musician or if you finally want to proof it to Mr. Bear. <br/>This app is groooooovy!<br/>Try it now for an extra-spectacular groove madness.</p>
        <Image1 style={imageStyle}/>
        <Image2 style={imageStyle}/>
        <Image3 style={imageStyle}/>
        <Image4 style={imageStyle}/>
        <p>Boom Clap Bo-Boom Clap Yeeeeaaah ...<br/><br/>xoxo, Rhythm Bear</p>
    </div>
}

const getPage = () => {
    const content = getContent()
    return AppPage({app:site,content})
}

const site = {
    name: "Rhythm Bear",
    shortDescription: <span>You got the groove?<br/>Proof it to Mr. Bear right now ...</span>,
    description: "ABC DEF",
    categories: ["music"],
    tags: ["game","music","learn","groovy"],
    style: {
        menu: {
            background: ""
        }
    },
    pageURL: "RhythmBear",
    getContent,
    getPage,
    downloadLinks: {
        appStore: "https://apps.apple.com/us/app/rhythm-bear/id1454143021",
        playStore: "COMING_SOON"
    },
    icon 
}
export default site