import React from 'react'
import {GoBackButton,Menu,MenuBackButton} from './Header/menuBtn'
import {HeaderPage} from './Header'

import './layoutCustom.css'

const AppStoreBadge = ({url}) => {
  return <a href={url} style={{display:'inline-block',overflow:'hidden',background:'url(https://linkmaker.itunes.apple.com/en-us/badge-lrg.svg?releaseDate=2019-10-01&kind=iossoftware&bubble=ios_apps) no-repeat',width: '135px',height:'40px',marginRight: '5px', float:'left'}}></a>
}


const AppStoreBadgeSmall = ({url}) => {
  return <a href={url} style={{display:'inline-block',overflow:'hidden',background:'url(https://linkmaker.itunes.apple.com/assets/shared/badges/en-us/appstore-sm.svg) no-repeat', width: '60px',height:'15px'}}></a>
}


const PlayStoreBadge = ({url}) => {
  
  if (!url) {
    return null
  }

  if (url == "COMING_SOON") {
    return <div style={{display:'inline-block', boxSizing: 'border-box',fontSize:"12px",height:'40px',padding:'7px 10px', border:'1px solid black', borderRadius:'5px',opacity:0.6}}>Android coming soon</div>
  }
  return null //TOOD: make badge
}

export default ({app,content}) => {
  return <main>
    <div className={"site app " + (app.pageURL ? app.pageURL.toLowerCase() : "")} >
        <HeaderPage>
        <MenuBackButton to="/" text="PIANO BEAR'S HOME"/>
        </HeaderPage>
        <div className="pagePadding">
            <div className="appTitle">
                <h1>{app.name}</h1>
            </div>
            <div className="content">
            <app.icon />
            {/* <AppStoreBadgeSmall url={app.downloadLinks.appStore} /> */}
            <AppStoreBadge url={app.downloadLinks.appStore} />
            <PlayStoreBadge url={app.downloadLinks.playStore}/>
                <div class="textSection">{content}</div>
                <div class="downloadSection" style={{textAlign:'center'}}>
                <AppStoreBadge url={app.downloadLinks.appStore} />
                <PlayStoreBadge url={app.downloadLinks.playStore}/>
                </div>
            </div>
        </div>
    </div>
  </main>
}