import { blue } from "ansi-colors";
import icon from './icon'
import React from 'react'

const site = {
    name: "Buggy",
    shortDescription: <span>Learn to program with funky robots<br/>//TODO: download -> execute()</span>,
    description: "ABC DEF",
    categories: ["game"],
    tags: ["programming","robot","learn","code"],
    style: {
        menu: {
            background: ""
        }
    },
    icon 
}
export default site