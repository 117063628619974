import { blue } from "ansi-colors";
import React from "react"
import Popup from '../../components/Popup'
import icon from './icon'

const music = [{
        name: "Bear plays Beethoven",
        shortDescription: "Nice music ... \"a must hear\"",
        tooltip: "Listen to my music",
        tags: ["outrageous","ingenious","piano"],
        onClick: ()=> {
            Popup.alert("Sorry, this piece is not available in your country")
        }
    },
    {
        name: "My first piece",
        shortDescription: "Soft piano music ... that's how it all started",
        tooltip: "Listen to my music",
  
        tags: ["very nice","must-hear","piano"],
        onClick: () => {
            Popup.alert(<div>Access for members only!<br/><br/>Press "I'm a V.I.P. member" to continue</div>)
        }
    },
    {
        name: "Another bear",
        shortDescription: "cool dude, cool music",
        tooltip: "GROOVY",
        tags: ["groovy","let's swing","bear music"],
        onClick: () => {
            window.location = "https://www.youtube.com/watch?v=9JDzlhW3XTM"
        }
    },
    {
        name: "Other music",
        shortDescription: "Let's go party!!!",
        tooltip: "AWESOME",
  
        tags: ["woooow","pure music","no piano but good"],
        onClick: () => {
            window.location = "https://www.youtube.com/watch?v=ZyhrYis509A"
        }
    }
]
export default music