import React from "react"
import { Link } from "gatsby"
import Popup from '../components/Popup'

import Layout from "../components/layout"
import NewsletterButton from './Newsletter'
// import Background from './Background'
import Keyboard from '../components/Keyboard'
// import Image from "../components/image"
import Badge from '../components/Header/imagePianoBear'
import SEO from "../components/seo"
import HardButton from '../components/HardButton'
import {ListOfAllApps,ListOfAllAppsByCategory} from '../Apps'
// import { relative } from "path";
import {SpiderController,BugController} from '../bugs/bug'
import { black } from "ansi-colors";

const afterSpiderButton = () => {
  window.spiders = new SpiderController({maxBugs:30,minBugs:30,minDelay:1,maxDelay:1000}); 
  document.body.style.background = "black";
  updateNoButton("");
  setTimeout(()=>{
    updateNoButton("Don't click this button again!")
  },2000);
  setTimeout(()=>{document.body.style.background = null},500)
}

const updateNoButton = (text) => {
  try {
    window.dontClickButton = text; //ever again
    document.getElementById("dontClickButton").innerText = window.dontClickButton; 
  } catch (e) {

  }
}

const spiderButton = () => {
  try {
    if (!window.spiders) {
      Popup.create({
        title: '',
        content: 'Do you like fat, hairy spiders?',
        className: 'alert',
        buttons: {
            right: [{text:'NO',action: ()=>{Popup.close()
              afterSpiderButton();
            }},
            {text:'YES',action: ()=>{Popup.close()
              afterSpiderButton();
            }}]
        }
      }, true);
    } else {
      window.spiders.killAll();
      setTimeout(()=>{
        window.spiders.end();
        updateNoButton("This is not a button"); //ever again
      },4000);
      updateNoButton("");
    }
  } catch(e) {

  }
}
  

const dontClickButton = <button id="dontClickButton" onClick={()=>{spiderButton();}} style={{position:'absolute', top: '10px', right: '5px', maxWidth: '110px', lineHeight: '12px', fontSize: '12px', border: 'none', background: 'transparent', color: '#FFF', border: '1px solid white', borderRadius: '15px', padding: '4px', textTransform:'uppercase', cursor:'pointer'}}>{"Don't click this button"}</button>

const IndexPage = () => {
  React.useEffect(()=> {
    setTimeout(function() {
        new BugController({maxBugs:1}); //monitorMouseMovement
      },3000);
  },[])

  return (
  <Layout>
    <SEO title="PianoBear: Apps, Music and a Bear" keywords={[`piano bear`, `music`, `app`]} />
    <Badge/>
    <div className="content main">
    <Keyboard/>
    <div className="text" style={{position: 'relative'}}>
    
    <h1>Welcome!</h1>
    {dontClickButton}
    <p>Check out what's new <a style={{color: 'white', textDecoration: 'none', fontWeight: 'bold' }} href="http://twitter.com/realPianoBear">@realPianoBear</a><br/>More about the bear -> <HardButton/></p>
    <NewsletterButton/>
    </div>
    <ListOfAllAppsByCategory/>
    <div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}>
      {/* <Image /> */}
    </div>
    <Keyboard bottom={true}/>
    </div>
    <span className="link" style={{textDecoration:'underline', margin:'0px auto 20px auto', display:'block', textAlign: 'center'}} onClick={()=>{Popup.alert("There is no page 2")}}>Go to page 2</span>
   
  </Layout>
)}

export default IndexPage
