import { blue } from "ansi-colors";
import icon from './icon'
import React from 'react'

const site = {
    name: "Guitar Champion",
    shortDescription: "Are you the best guitarero? Learn songs and play like a pro ...",
    description: "ABC DEF",
    categories: ["music"],
    tags: ["music","learn","highscore"],
    style: {
        menu: {
            background: ''
        }
    },
    icon,
    pageURL: "GuitarChampion",
    hidden: "SOON AVAILABLE",
    hiddenAlert: <div>rock 'n' roll .. soon at your fingertips <br/>🤘🎸🤘</div>,
}
export default site
