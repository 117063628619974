import { blue } from "ansi-colors";
import icon from './icon'
import React from 'react'

const site = {
    name: "Piano Champion",
    shortDescription: "Learn to play the piano and make a new world record",
    description: "ABC DEF",
    categories: ["music"],
    tags: ["music","learn","piano","highscore"],
    style: {
        menu: {
            background: ''
        }
    },
    icon,
    hidden:"SOON AVAILABLE",
    hiddenAlert: <div>soon publicly available for your piano pleasure ... 🎹🏅<br/>subscribe as beta tester to give it a try now</div>,
}
export default site
