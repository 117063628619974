import {FaEnvelope} from "react-icons/fa";
import React from "react";
import { blue } from "ansi-colors";
import Popup from '../components/Popup'

// export default function() {
//     return (
//         <p>
//             <div style={{background: '#03c0ca', maxWidth: '340px', position: 'relative', transform:'rotateZ(2deg)', color: 'white', padding: '10px 20px', margin: '0px -40px',textShadow: '0 0 5px rgba(0,0,0,0.2)' }}>
//             Stay awesome: 
//             <a href="" style={{paddingLeft: 5, color: '#FFFFFF',textDecoration: 'none'}}>sign up for the Newsletter</a> <FaEnvelope/>
//             </div>
//         </p>
//     )
// }


export default function() {
    return (
        <p style={{textAlign:'center'}}>
            Stay awesome:  <span className="link" style={{ padding: '5px'}}><a style={{paddingLeft: 5,  cursor: 'pointer'}} onClick={()=>{Popup.alert(<React.Fragment>Newsletter inbox is full. Please come back later ...<br/>meanwhile check: <a href="http://www.twitter.com/realPianoBear">www.twitter.com/realPianoBear</a></React.Fragment>); return false;}}>Newsletter</a> <FaEnvelope/></span>
        </p>
    )
}