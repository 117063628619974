import { blue } from "ansi-colors";
import icon from './icon'
import React from 'react'

const site = {
    name: "Drum Champion",
    shortDescription: "Find out who's the worlds best drummer",
    description: "ABC DEF",
    categories: ["music"],
    tags: ["music","learn","drums","rhythm","highscore"],
    style: {
        menu: {
            background: ''
        }
    },
    icon,
    hidden: "SOON AVAILABLE",
    hiddenAlert: <div>🥁🥁🥁 ... soon available</div>,
    pageURL: "DrumChampion"
}
export default site
