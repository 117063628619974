/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import Popup from '../components/Popup'

// import Background from '../pages/Background'
import {HeaderStart} from "./Header"

import './listOfAllAppsNew.css'
import "./Alert.css"


const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <HeaderStart siteTitle={data.site.siteMetadata.title} />
        <div id="wrapper">
          <main>{children}</main>
          <footer>
            © {new Date().getFullYear()}, Pianobear<br/><a href="https://twitter.com/realPianoBear">@realPianoBear</a>
            <br/><br/>by<br/>❤︎, music and honey unLTD<br/><br/>
            <button onClick={()=>{document.body.innerHTML = ""; document.body.style.background = 0; setTimeout(()=>{alert("you deleted the internet")},1000); }} style={{background: 'none', border: '1px solid rgba(0,0,0,0.2)', borderRadius: '5px',color: 'rgba(0,0,0,0.2)'}} title="this is nothing">Secret button</button>
          </footer>
          <Popup/>
          
          {/* <Background/> */}
          <audio id="playAudio" style={{width: '1px', height: '1px', position: 'absolute', bottom: 0, left: '-1000px' }} controls volume preload='none' src='https://www.pianobear.cool/pianobearjinglev1.mp3'></audio>
        </div>
        <div className="rainbow"></div>
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
