import { blue } from "ansi-colors";
import icon from './icon'

const site = {
    name: "Groove-O-Meter (Pro)",
    shortDescription: "Metronome with visual feedback",
    description: "ABC DEF",
    categories: ["music"],
    tags: ["music","learn","practice","rhythm"],
    style: {
        menu: {
            background: ""
        }
    },
    icon,
    hidden: "SOON AVAILABLE",
    hiddenAlert: "coming soon ... stay tuned 🥁🥁🥁",
    pageURL: "GrooveOMeter"
}
export default site